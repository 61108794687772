import { Element } from "@/scripts/extends";
import { dom } from "@/scripts/utilities";

export default class Accordion extends Element {
	mounted() {
		this.trigger = this.el.querySelector(".c-accordion__header");
		this.resized = false;
		this.elementState = this.el.getAttribute("data-state");

		if (this.el.classList.contains("is-open") || this.elementState) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	events() {
		this.resizeObserver = dom.setResizeObserver(() => this.onResize());
		this.resizeObserver.observe(this.el);

		this.trigger.addEventListener("click", (e) => this.onClick(e));
	}

	destroy() {
		this.trigger.removeEventListener("click", (e) => this.onClick(e));
	}

	onClick(e) {
		e.preventDefault();
		this.setState();
	}

	setState() {
		if (!this.isOpen) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	setOpenState() {
		this.el.classList.add("is-open");
		this.trigger.setAttribute("aria-expanded", "true");
		this.isOpen = true;
	}

	setClosedState() {
		this.el.classList.remove("is-open");
		this.trigger.setAttribute("aria-expanded", "false");
		this.isOpen = false;
	}

	onResize() {
		if (this.el.classList.contains("mobile-only")) {
			if (window.innerWidth >= 768) {
				this.resized = true;
				this.setOpenState();
			}

			if (window.innerWidth <= 768 && this.resized) {
				this.setClosedState();
				this.resized = false;
			}
		}
	}
}
