import { Element } from "@/scripts/extends";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Slider extends Element {
	mounted() {
		this.toggleClass = "is-active";
		this.isOpen = false;
		this.toggleState = this.toggleState.bind(this);

		this.menu = this.el.querySelector("#menu");

		this.initEvents();
		this.scrollMenu();
	}

	initEvents() {
		this.menu.addEventListener("click", this.toggleState);
	}

	scrollMenu() {
		gsap.registerPlugin(ScrollTrigger);

		const showAnim = gsap.from(".c-nav", {
			yPercent: -100,
			paused: true,
			duration: 0.2,
		  }).progress(1);

		ScrollTrigger.create({
			start: "top top",
			end: 99999,
			onUpdate: (self) => {
			  self.direction === -1 ? showAnim.play() : showAnim.reverse()
			}
		  });
	}

	toggleState() {
		if (this.el.classList.contains(this.toggleClass)) {
			this.handleClose();
		} else {
			this.handleOpen();
		}
	}

	handleOpen() {
		this.isOpen = true;

		this.el.classList.add(this.toggleClass);
		this.menu.classList.add(this.toggleClass);

		document.documentElement.style.overflow = "hidden";
	}

	handleClose() {
		this.isOpen = false;

		this.el.classList.remove(this.toggleClass);
		this.menu.classList.remove(this.toggleClass);

		document.documentElement.style.overflow = "auto";
	}

	destroy() {
		this.menu.removeEventListener("click", this.toggleState);
	}

}
