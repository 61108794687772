import { Element } from "@/scripts/extends";
import { dom } from "@/scripts/utilities";

export default class Filter extends Element {

	mounted() {
		this.overview = this.el;
		this.toggleClasses = "hidden";

		this.triggers = this.el.querySelectorAll(".c-mobile-filter__trigger");
		this.filters = this.el.querySelector(".c-overview-filters");
		this.elementState = this.filters.getAttribute("data-state");

		if (this.overview.classList.contains("is-open") || this.elementState) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	events() {
		this.resizeObserver = dom.setResizeObserver(() => this.onResize());
		this.resizeObserver.observe(this.el);

		this.triggers.forEach(el => {
			el.addEventListener("click", (e) => this.onClick(e));
		})
	}

	onClick(e) {
		e.preventDefault();
		this.setState();
	}

	setState() {
		if (!this.isOpen) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	setOpenState() {
		if (window.innerWidth <= 768) {
			this.overview.style.transform = "none";
			document.documentElement.style.overflow = "hidden";
		}

		this.filters.classList.remove(this.toggleClasses);

		this.isOpen = true;
	}

	setClosedState() {
		this.overview.style.transform = null;
		this.filters.classList.add(this.toggleClasses);
		document.documentElement.style.overflow = "auto";

		this.isOpen = false;
	}

	destroy() {
		this.triggers.forEach(el => {
			el.removeEventListener("click", () => this.toggleMobileFilters());
		})
	}

	onResize() {
		if (window.innerWidth >= 768) {
			if (this.isOpen) {
				document.documentElement.style.overflow = "auto";
			}
		}
	}
}
