import { Element } from "@/scripts/extends";

export default class ContactTracking extends Element {

	events() {
		this.el.addEventListener("click", () => this.onClick(this.el));
	}

	onClick(el) {
		if (window.dataLayer) {
			let dataEvent = el.getAttribute("data-event");
			let dataType = el.getAttribute("data-type");
			let dataName = el.getAttribute("data-name");
			let dataId = el.getAttribute("data-id");

			window.dataLayer.push({
				"event": dataEvent,
				"click_type": dataType,
				"click_name": dataName,
				"click_id": dataId,
			});
		}
	}

	destroy() {
		this.el.removeEventListener("click", () => this.onClick());
	}
}
