import { Element } from "@/scripts/extends";

export default class Filter extends Element {

	mounted() {
		this.filters = this.el.querySelectorAll(".c-checkbox__input");

		if (this.filters) {
			this.filters.forEach(el => {
				el.addEventListener("change", (e) => this.onFilter(el, e));
			});
		}
	}

	onFilter(el, e) {
		if (e.target.checked) {
			if (window.dataLayer) {
				let dataTopic = el.getAttribute("data-topic");
				let dataSelect = el.getAttribute("data-select");
				let dataId = el.getAttribute("data-id");

				window.dataLayer.push({
					"event": "filter_click",
					"filter_topic": dataTopic,
					"filter_select": dataSelect,
					"filter_id": dataId,
				});
			}
		}
	}

	destroy() {
		this.filters.forEach(el => {
			el.removeEventListener("change", () => this.onFilter());
		});
	}
}
